.container::-webkit-scrollbar {
  display: none;
}

.container {
  overflow-y: hidden;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
  background: #555;
}



@media (max-width: 992px) {
  .backgrounds {
    overflow: scroll;
    height: auto;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  background-color: black;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden],
template {
  display: none;
}

:root:lang(hi) {
  --font-main: "Poppins", sans-serif;
}

@font-face {
  font-display: swap;
  font-family: SF Pro Display;
  font-style: italic;
  font-weight: 900;
  src: url(/public/assets/fonts/SFProDisplay-BlackItalic.woff2) format("woff2"),
    url(/public/assets/fonts/SFProDisplay-BlackItalic.woff2) format("woff");
}

@font-face {
  font-display: swap;
  font-family: SF Pro Display;
  font-style: italic;
  font-weight: 800;
  src: url(/public/assets/fonts/SFProDisplay-HeavyItalic.woff2) format("woff2"),
    url(/public/assets/fonts/SFProDisplay-HeavyItalic.woff2) format("woff");
}

@font-face {
  font-family: SF Pro Display;
  font-style: italic;
  font-weight: 400;
  src: url(/public/assets/fonts/SFProDisplay-RegularItalic.woff2) format("woff2"),
    url(/public/assets/fonts/SFProDisplay-RegularItalic.woff2) format("woff");
}

@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
}

@media (min-width: 768px) {
  .header {
    padding: 40px 20px;
  }
}

@media (min-width: 1440px) {
  .header {
    padding: 40px;
  }
}

@media (min-width: 1920px) {
  .header {
    padding: 40px 260px;
  }
}

.logo {
  font-size: 0;
}

.logo source svg {}

.plane {
  animation-duration: 3.4s;
  animation-iteration-count: infinite;
  animation-name: fly;
  animation-timing-function: ease-in-out;
  background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
  background-repeat: no-repeat;
  /*  height: 203px; */
  width: 420px;
  background-size: cover;
}

@media (max-width: 768px) {
  .plane {
    --translate-value: 3px;
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
    background-size: cover;
    height: 300px;
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 1468px) {
  .plane {
    --translate-value: 3px;
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
    background-size: cover;
    height: 339px;
    width: 700px;
  }
}

@media (min-width: 1024px) {
  .plane {
    --translate-value: -20px;
    position: absolute;
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
    right: -175px;
    top: 95px;
  }
}

@media (min-width: 1440px) {
  .plane {
    --translate-value: 15px;
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
    height: 768px;
    right: -267px;
    top: 105px;
    width: 1079px;
  }
}

@media (min-width: 1920px) {
  .plane {
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/plane-tablet-768@2x.avif");
    --translate-value: 11px;
    right: -99px;
  }
}

@keyframes fly {

  0%,
  to {
    transform: translateY(0) rotate(0);
  }

  50% {
    transform: translateY(var(--translate-value)) rotate(2deg);
  }
}

.text_size_xxl {
  font-size: 80px;
  line-height: 1;
}

@media (min-width: 768px) {
  .text_size_xxl {
    font-size: 140px;
  }
}

@media (min-width: 1440px) {
  .text_size_xxl {
    font-size: 200px;
  }
}

.text_size_md {
  font-size: 20px;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .text_size_md {
    font-size: 22px;
  }
}

@media (min-width: 1440px) {
  .text_size_md {
    font-size: 27px;
  }
}

.text_theme_with_shadow {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--color-red);
  text-shadow: 0 0 4px #d3032a, 0 0 24px #d3032a, 0 0 24px #d3032a;
}

@media (min-width: 768px) {
  .text_theme_with_shadow {
    -webkit-text-stroke-width: 3px;
  }
}

@media (min-width: 1440px) {
  .text_theme_with_shadow {
    -webkit-text-stroke-width: 5px;
  }
}

.text_theme_white {
  color: var(--color-white);
}

.text_weight_black {
  font-weight: 900;
}

.text_weight_regular {
  font-weight: 400;
}

.text_is_uppercase {
  text-transform: uppercase;
}

.text_is_italic {
  font-style: italic;
}

.link {
  border-radius: 70px;
  text-decoration: none;
  border: 2px solid white;
  transition-duration: 0.2s;
  transition-property: box-shadow, opacity;
  transition-timing-function: ease-in-out;
}

.link_theme_red {
  background-color: red;
  color: white;
  font-size: 18px;
  line-height: 1;
}

.link_theme_red:hover {
  box-shadow: 0 0 23px #fc1010;
}

.link_theme_red:active {
  opacity: 0.7;
}

@media (min-width: 768px) {
  .link_theme_red {
    font-size: 20px;
  }
}

@media (min-width: 1440px) {
  .link_theme_red {
    font-size: 24px;
  }
}

.link_size_md {
  padding: 21px 15px;
}

@media (min-width: 768px) {
  .link_size_md {
    padding: 25px 15px;
  }
}

@media (min-width: 1440px) {
  .link_size_md {
    padding: 30px 15px;
  }
}

.link_is_uppercase {
  text-transform: uppercase;
}

.link_is_italic {
  font-style: italic;
}

.link_weight_extrabold {
  font-weight: 800;
}

.link_animation_pulse {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  overflow: hidden;
  position: relative;
}

.link__highlight {
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  animation-name: highlight;
  animation-timing-function: ease-in-out;
  display: flex;
  gap: 20px;
  height: 100%;
  position: absolute;
  right: 0;
  top: -80px;
  transform: translateX(-100%);
  width: 100%;
  z-index: -1;
}

.link__highlight:after,
.link__highlight:before {
  background-color: white;
  content: "";
  height: 500%;
  opacity: 0.3;
  transform: rotate(45deg);
}

.link__highlight:before {
  width: 40px;
}

.link__highlight:after {
  width: 10px;
}

@keyframes highlight {
  0% {
    transform: translateX(-150%);
  }

  to {
    transform: translateX(150%);
  }
}

@keyframes pulse {

  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

.main {
  overflow: hidden;
  padding: 0 0 145px;
}

@media (min-width: 1024px) {
  .main {
    display: flex;
    /* padding: 70px 0 210px; */
    position: relative;
  }
}

@media (min-width: 1440px) {
  .main {
    padding: 60px 0 250px;
  }
}

@media (min-width: 1920px) {
  .main {
    padding: 60px 0 300px;
  }
}

.main__plane {
  margin: 0 auto 14px;
}

@media (min-width: 768px) {
  .main__plane {
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .main__plane {
    margin: 0;
  }
}

.main__content {
  margin: 0 auto;
  max-width: 320px;
  padding: 0 9px;
}

@media (min-width: 768px) {
  .main__content {
    max-width: 530px;
    padding: 0;
    transform: translateY(-30px);
  }
}

@media (min-width: 1024px) {
  .main__content {
    margin: 0;
    max-width: 570px;
    padding: 0 20px;
    transform: translateY(0);
  }
}

@media (min-width: 1440px) {
  .main__content {
    max-width: 755px;
  }
}

@media (min-width: 1920px) {
  .main__content {
    margin: 0 0 0 260px;
    padding: 0;
  }
}

.main__title {
  font-size: inherit;
  margin: 0 0 50px;
  text-align: center;
}

@media (min-width: 768px) {
  .main__title {
    margin: 0 0 60px;
  }
}

@media (min-width: 1440px) {
  .main__title {
    margin: 0 0 76px;
  }
}

.main__sum {
  display: inline-block;
  letter-spacing: 0.02em;
  margin: 0 0 15px;
}

@media (min-width: 768px) {
  .main__sum {
    margin: 0 0 22px;
  }
}

@media (min-width: 1440px) {
  .main__sum {
    margin: 0 0 27px;
  }
}

.main__text {
  letter-spacing: 0.02em;
}

@media (min-width: 768px) {
  .main__text {
    display: inline-block;
    max-width: 400px;
  }
}

.main__link {
  cursor: pointer;
  display: block;
  text-align: center;
  color: white;
}

@media (min-width: 768px) {
  .main__link {
    margin: 0 auto;
    max-width: 310px;
  }
}

@media (min-width: 1440px) {
  .main__link {
    max-width: 368px;
  }
}

.body {
  background-color: black;
  min-height: 100vh;
  position: relative;
}

@supports (min-height: 100dvh) {
  .body {
    min-height: 100dvh;
  }
}

.body__lights {
  overflow: hidden;
  right: 0;
  top: 0;
  z-index: -5;
}

.body__fire,
.body__lights {
  bottom: 0;
  left: 0;
  position: absolute;
}

.body__fire {
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-name: fire;
  background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/fire-tablet-768@2x.avif");
  animation-timing-function: ease-in-out;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 339px;
  mix-blend-mode: screen;
  width: 100%;
  z-index: -4;
}

@media (min-width: 768px) {
  .body__fire {
    --fire-changed-value: 365px;
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/fire-mobile@2x.avif");
  }
}

@media (min-width: 1024px) {
  .body__fire {}
}

@media (min-width: 1440px) {
  .body__fire {
    --fire-default-value: 484px;
    --fire-changed-value: 499px;

    height: 484px;
  }
}

@media (min-width: 1920px) {
  .body__fire {
    --fire-default-value: 558px;
    --fire-changed-value: 593px;

    height: 558px;
  }
}

.body__smoke {
  bottom: 0;
  left: 0;
  mix-blend-mode: color-dodge;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -5;
}

.body__smoke:after {
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-name: smoke;
  animation-timing-function: ease-in-out;
  background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/smoke-mobile@2x.avif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}

@media (min-width: 768px) {
  .body__smoke:after {
    background-image: url("https://1wenwl.xyz/v3/aviator-fire/assets/images/smoke-tablet-768@2x.avif");
  }
}

@media (min-width: 1024px) {
  .body__smoke:after {}
}

@media (min-width: 1440px) {
  .body__smoke:after {}
}

.body__light {
  background: radial-gradient(50% 50% at 50% 50%,
      rgba(255, 0, 0, 0.2) 0,
      rgba(255, 0, 0, 0) 100%);
  position: absolute;
  z-index: -5;
}

.body__light_position_top {
  height: 709px;
  left: -364px;
  top: -355px;
  width: 709px;
}

@media (min-width: 768px) {
  .body__light_position_top {
    height: 955px;
    left: -505px;
    top: -478px;
    width: 955px;
  }
}

@media (min-width: 1440px) {
  .body__light_position_top {
    height: 1200px;
    left: -599px;
    top: -608px;
    width: 1200px;
  }
}

.body__light_position_center {
  height: 668px;
  left: 90px;
  top: 50%;
  transform: translateY(-50%);
  width: 668px;
}

@media (min-width: 768px) {
  .body__light_position_center {
    height: 1282px;
    left: 180px;
    top: -361px;
    transform: none;
    width: 1282px;
  }
}

@media (min-width: 1024px) {
  .body__light_position_center {
    left: 383px;
    top: -654px;
  }
}

@media (min-width: 1440px) {
  .body__light_position_center {
    height: 1930px;
    left: 531px;
    top: -1048px;
    width: 1930px;
  }
}

.body__line {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.body__line-contour {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: pulse-shadow;
  animation-timing-function: ease-in-out;
}

.body__line_is_mobile {
  display: block;
}

@media (min-width: 768px) {
  .body__line_is_mobile {
    display: none;
  }

  .body__line_is_md {
    display: block;
  }
}

@media (min-width: 1024px) {
  .body__line_is_md {
    display: none;
  }

  .body__line_is_lg {
    display: block;
  }
}

@media (min-width: 1440px) {
  .body__line_is_lg {
    display: none;
  }

  .body__line_is_xl {
    display: block;
  }
}

@media (min-width: 1920px) {
  .body__line_is_xl {
    display: none;
  }

  .body__line_is_xxl {
    display: block;
  }
}

.body__flame {
  display: none;
}

@media (min-width: 768px) {
  .body__flame {
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-name: flame;
    animation-timing-function: ease-in-out;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    display: block;
    mix-blend-mode: screen;
    position: absolute;
    z-index: -3;
  }

  .body__flame_position_left {
    --flame-default-value: 485px;
    --flame-changed-value: 522px;

    height: 485px;
    left: 0;
    width: 297px;
  }
}

@media (min-width: 1024px) {
  .body__flame_position_left {
    --flame-changed-value: 500px;
  }
}

@media (min-width: 1440px) {
  .body__flame_position_left {
    --flame-default-value: 555px;
    --flame-changed-value: 572px;

    height: 555px;
    width: 340px;
  }
}

@media (min-width: 1920px) {
  .body__flame_position_left {
    --flame-default-value: 671px;
    --flame-changed-value: 713px;

    height: 671px;
    width: 475px;
  }
}

@media (min-width: 768px) {
  .body__flame_position_right {
    --flame-default-value: 464px;
    --flame-changed-value: 499px;

    height: 464px;
    right: 0;
    width: 282px;
  }
}

@media (min-width: 1024px) {
  .body__flame_position_right {
    --flame-changed-value: 478px;
  }
}

@media (min-width: 1440px) {
  .body__flame_position_right {
    --flame-default-value: 641px;
    --flame-changed-value: 661px;

    height: 641px;
    width: 376px;
  }
}

@media (min-width: 1920px) {
  .body__flame_position_right {
    --flame-default-value: 641px;
    --flame-changed-value: 681px;

    height: 641px;
    width: 500px;
  }
}

@keyframes fire {

  0%,
  to {
    height: var(--fire-default-value);
  }

  50% {
    height: var(--fire-changed-value);
  }
}

@keyframes smoke {

  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes pulse-shadow {

  0%,
  to {
    filter: drop-shadow(0 0 0 #d3032a);
  }

  50% {
    filter: drop-shadow(0 0 10px #d3032a);
  }
}

@keyframes flame {

  0%,
  to {
    height: var(--flame-default-value);
  }

  50% {
    height: var(--flame-changed-value);
  }
}

body {
  font-family: var(--font-main);
}

*,
:after,
:before {
  box-sizing: border-box;
}

:root {
  --font-main: "SF Pro Display", sans-serif;
  --color-white: #fff;
  --color-red: red;
  --color-bg: #020617;
  --translate-value: 10px;
  --fire-default-value: 339px;
  --fire-changed-value: 383px;
  --flame-default-value: 0;
  --flame-changed-value: 0;
}


.install-button-div {
  display: none !important;
}

@media (max-width:600px) {
  .install-button-div {
    display: flex !important;
    margin-top: 0px;
  }

 
}

.register-heading {
  padding-top: 150px;
}